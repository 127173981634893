@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Dubai Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Dubai Regular'), url("../src/styles/font/dubai/Dubai-Regular.woff") format('woff');
    }

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    /* display: none; */
    /* overflow: hidden; */
    height: 8px;
}

/* width */
::-webkit-scrollbar {
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1A1819;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #1A1819;
  }

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}